<!--
 * @Description:维护管理 上报工单 uploadWorkList
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:16:43
 * @LastEditors: zhoucheng
-->
<template>
  <div class='uploadWorkList-mainBody'>
    <van-nav-bar title="工单上报"
                 left-text="返回"
                 left-arrow
                 placeholder
                 fixed
                 @click-left="handleClickBack" />
    <div class="mainBody">
      <van-row class="lineBottom">
        <!-- 问题描述图片 -->
        <van-row class="uploadPic">
          <van-uploader upload-text='拍照/上传照片'
                        v-model="fileList"
                        max-count="1"
                        :after-read='afterRead'
                        preview-size='101' />
        </van-row>
        <!-- 问题描述 -->
        <van-row class="content">
          <van-field class="content-content"
                     v-model="deviceFaultDesc"
                     rows="2"
                     autosize
                     type="textarea"
                     maxlength="50"
                     placeholder="请输入问题描述"
                     show-word-limit />
        </van-row>
      </van-row>
      <van-row class="lineTop">
        <!-- 问题类型 -->
        <van-row>
          <van-field v-model="questionType"
                     is-link
                     readonly
                     label="问题类型"
                     placeholder="请选择"
                     @click.native="questionTypeShow = true" />
          <van-popup v-model="questionTypeShow"
                     round
                     position="bottom">
            <van-picker show-toolbar
                        :columns="questionTypeList"
                        @confirm="selectQuestionType"
                        @cancel="questionTypeShow = false" />
          </van-popup>
        </van-row>
        <!-- 设备类型 -->
        <van-row>
          <van-field v-model="deviceType"
                     is-link
                     readonly
                     label="设备类型"
                     placeholder="请选择"
                     @click="deviceTypeShow = true" />
          <van-popup v-model="deviceTypeShow"
                     round
                     position="bottom">
            <van-picker show-toolbar
                        :columns="deviceTypeList"
                        @confirm="selectDeviceType"
                        @cancel="deviceTypeShow = false" />
          </van-popup>
        </van-row>
        <!-- 设备名称 -->
        <van-row>
          <van-field v-model="deviceName"
                     is-link
                     readonly
                     label="设备名称"
                     placeholder="请选择"
                     @click="handleClickDeviceName" />
          <van-popup v-model="deviceNameShow"
                     round
                     position="bottom">
            <van-field class="linefield"
                       style="margin-top:20px;text-align:left"
                       v-model="deviceNameField">
              <template #button>
                <van-icon name="search"
                          @click="queryDeviceTypeItem" />
              </template>
            </van-field>
            <van-picker :columns="deviceNameList"
                        show-toolbar
                        @confirm="selectDeviceName"
                        @cancel="deviceNameShow = false">
            </van-picker>
          </van-popup>
        </van-row>
      </van-row>
      <!-- 工单备注 -->
      <van-row class="lineMid">
        <van-row class="lineTitle">工单备注</van-row>
        <van-row class="content">
          <van-field class="content-content"
                     v-model="ticketRemark"
                     rows="2"
                     autosize
                     type="textarea"
                     maxlength="50"
                     placeholder="请输入工单备注"
                     show-word-limit />
        </van-row>
      </van-row>
    </div>
    <van-button class="bottomButton"
                @click="uploadWorkList">上 报</van-button>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      questionTypeList: [], // 问题类型列表
      questionType: '', // 问题类型
      questionTypeCode: '', // 问题类型编码
      questionTypeShow: false, // 问题类型弹窗
      deviceTypeList: [], // 设备类型列表
      deviceType: '', // 设备类型
      deviceTypeCode: '', // 设备类型编号
      deviceTypeShow: false, // 设备类型弹窗
      deviceNameList: [], // 设备名称列表
      deviceName: '', // 设备名称
      deviceId: '', // 设备编号
      deviceNameShow: false, // 设备名称弹窗
      deviceFaultDesc: '', // 问题描述
      ticketRemark: '', // 工单备注
      deviceNameField: '', // 设备名称弹窗内
      fileList: [],
      picUrl: '',
      operateManaList: {}// 运维单位
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryQuestionType()
    this.queryDeviceType()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询设备类型
    queryDeviceTypeItem () {
      const info = {
        deviceType: this.deviceTypeCode, // 设备类型
        deviceId: this.deviceNameField // 设备编号
      }
      this.$uploadWorkList.queryDeviceInfo(info).then(res => {
        this.deviceNameList = []
        const list = { text: '', value: '' }
        list.text = res.resultEntity.device_name
        list.value = res.resultEntity.device_id
        this.deviceNameList.push(list)
        this.$router.go(-1)
      })
    },
    // 上传 读取完成
    afterRead (file) {
      if (file instanceof Array) {
        file = file[0]
      }
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.1,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            file.status = 'done'
            self.picUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },
    // 根据设备获取运维单位信息
    handleClickSearchDevice () {
      const info = {
        deviceId: this.deviceId,
        deviceType: this.deviceTypeCode
      }
      this.$uploadWorkList.getMaintenanceByDevice(info).then(res => {
        if (res.resultEntity !== undefined && res.resultEntity !== null) {
          this.operateManaList = res.resultEntity
        }
        // else {
        //   this.$toast('该设备暂未绑定运维单位！')
        // }
      })
    },
    // 巡检员上报工单
    uploadWorkList () {
      if (!this.questionTypeCode) {
        this.$toast('请选择问题类型')
      } else if (!this.deviceTypeCode) {
        this.$toast('请选择设备类型')
      } else if (!this.deviceId) {
        this.$toast('请选择设备')
      } else if (!this.deviceFaultDesc) {
        this.$toast('请输入问题描述')
      } else if (!this.picUrl) {
        this.$toast('请上传图片')
      } else if (!this.ticketRemark) {
        this.$toast('请输入工单备注')
      } else if (this.picUrl && this.questionTypeCode && this.deviceTypeCode && this.deviceId && this.deviceFaultDesc && this.ticketRemark) {
        const info = {
          deviceTypeCode: this.deviceTypeCode, // 设备类型
          deviceId: this.deviceId, // 设备编号
          deviceFaultTypeCode: this.questionTypeCode, // 问题类型
          deviceFaultDesc: this.deviceFaultDesc, // 问题描述
          ticketRemark: this.ticketRemark, // 工单备注
          picUrl: this.picUrl// 工单确认图片
        }
        this.$uploadWorkList.createWorkTicketByInspection(info).then(() => {
          if (Object.keys(this.operateManaList).length !== 0) {
            this.equipmentRepair()
          } else {
            this.$toast('上报成功但该设备暂未绑定运维单位，无法发送消息！')
            this.$router.go(-1)
          }
        })
      }
    },
    // 短信验证
    equipmentRepair () {
      const info = {
        deptName: this.operateManaList.maintenanceName,
        deviceAddress: this.operateManaList.address,
        phone: this.operateManaList.contactPhoneNumber,
        deviceType: this.deviceType,
        deviceId: this.deviceId,
        questionType: this.questionType
      }
      this.$waitConfirmWork.equipmentRepair(info).then(() => {
        this.$toast('上报成功，已向运维单位发送消息！')
        this.$router.go(-1)
      })
    },
    // 查询问题类型
    queryQuestionType () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '0C3163A66F344DB1A64DE99A717EE84A'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        const questionTypeList = []
        res.resultEntity.forEach(item => {
          questionTypeList.push({
            text: item.name,
            value: item.code
          })
        })
        this.questionTypeList = questionTypeList
      })
    },
    // 确认选择问题类型
    selectQuestionType (val) {
      this.questionType = val.text
      this.questionTypeCode = val.value
      this.questionTypeShow = false
    },
    // 查询设备类型
    queryDeviceType () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '9A5949F452B54BD9A96B9E978157CD1B'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        const deviceTypeList = []
        res.resultEntity.forEach(item => {
          deviceTypeList.push({
            text: item.name,
            value: item.code
          })
        })
        this.deviceTypeList = deviceTypeList
      })
    },
    //  设备类型选择框确认
    selectDeviceType (val) {
      this.deviceType = val.text
      this.deviceTypeCode = val.value
      this.deviceName = ''
      const info =
      {
        columnName: ['device_id', 'device_name'],
        tableName: 'tb_' + val.value
      }
      this.$queryDict.queryDict(info).then(res => {
        const deviceNameList = []
        res.resultEntity.forEach(item => {
          deviceNameList.push({
            text: item.name,
            value: item.code
          })
        })
        this.deviceNameList = deviceNameList
        this.deviceTypeShow = false
      })
    },
    // 点击设备名称
    handleClickDeviceName () {
      if (this.deviceNameList.length === 0) {
        this.$toast('请选择设备类型')
        return
      }
      this.deviceNameShow = true
      this.deviceNameField = ''
    },
    // 确认选择设备名称
    selectDeviceName (val) {
      this.deviceName = val.text
      this.deviceId = val.value
      this.deviceNameShow = false
      this.handleClickSearchDevice()
    },
    // 顶部返回按钮
    handleClickBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.uploadWorkList-mainBody {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .mainBody {
    height: calc(100% - @navHeight - 40px - 15px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .lineBottom {
    width: 93.6%;
    height: 220px;
    margin-left: 3.2%;
    margin-top: 5px;
    background: #ffffff;
    padding-top: 10px;
    .uploadPic {
      margin-left: 4%;
      margin-top: 2%;
    }
    .content {
      width: 92%;
      margin-left: 4%;
      .content-content {
        background: #f5f8fc;
      }
    }
  }
  .lineTop {
    width: 93.6%;
    height: 135px;
    margin-left: 3.2%;
    margin-top: 5px;
    background: #ffffff;
    position: relative;
    /deep/ .van-field__control {
      text-align: right;
    }
    .linefield {
      width: 93.6%;
      margin-left: 3.2%;
      height: 40px;
      line-height: 20px;
      background: #f1f4f8;
      border-radius: 30px;
      position: relative;
      /deep/ .van-field__control {
        text-align: left;
        vertical-align: middle;
      }
    }
    .lineText {
      font-family: PingFang-SC-Medium;
      color: #666666;
      font-size: 15px;
      width: 93.6%;
      margin-left: 3.2%;
      margin-top: 10px;
    }
    .lineTitle {
      width: 100%;
      height: 20px;
      line-height: 20px;
      padding-left: 4%;
      font-size: 15px;
      font-family: PingFang-SC-Medium;
      color: #333333;
    }
  }
  .lineMid {
    width: 93.6%;
    height: 130px;
    margin-left: 3.2%;
    margin-top: 1.5%;
    background: #ffffff;
    padding-top: 10px;
    .lineTitle {
      width: 100%;
      height: 20px;
      line-height: 20px;
      padding-left: 4%;
      font-size: 15px;
      font-family: PingFang-SC-Medium;
      color: #333333;
    }
    .content {
      width: 92%;
      margin-left: 4%;
      margin-top: 2%;
      .content-content {
        background: #f5f8fc;
      }
    }
  }
  .bottomButton {
    width: 93.6%;
    height: 40px;
    margin-left: 3.2%;
    margin-top: 2%;
    background: #19a9fc;
    color: #ffffff;
    font-family: PingFang-SC-Regular;
    font-size: 17px;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
  }
}
</style>
